import {defineComponent} from "vue";
import SearchSuggestions from "@/components/SearchSuggestions.vue";
import searchSuggestionsController from "@/search-suggestions-controller";
import {searchPath} from "@/routes";
import KeyCodes from "@/key-codes";
import {visit} from "@hotwired/turbo";


export const globalHeaderSelector = '#global-header';
export const GlobalHeader = defineComponent({

	components: {
		SearchSuggestions
	},

	data(): {
		currentSubPane: null | "search" | "menu",
		windowWidth: number,
		searchQuery: string,
		loading: boolean,
		searchPaneHasFocus: boolean,
		searchSuggestionsController: typeof searchSuggestionsController.state.value,
	} {
		return {
			currentSubPane: null,
			windowWidth: 0,
			searchQuery: '',
			loading: false,
			searchPaneHasFocus: false,
			searchSuggestionsController: searchSuggestionsController.state.value
		}
	},

	computed: {

		showSearch(): boolean {
			return this.currentSubPane == 'search';
		},

		showMenu(): boolean {
			if (this.horizontalMenu) return false;

			return this.currentSubPane == 'menu';
		},

		horizontalMenu(): boolean {
			return this.windowWidth >= 720;
		},

		searchQueryPresent(): boolean {
			return this.searchQuery.trim() != '';
		},

		searchCollapsed(): boolean {
			if (this.showSearch) return false;

			if (!this.horizontalMenu) return true;

			if (this.searchQueryPresent) return false;

			return true;
		},
	},

	watch: {
		horizontalMenu(doShow: boolean): void {
			if (doShow && this.currentSubPane == 'menu') {
				this.close();
			}
		},

		showSearch(doShow: boolean): void {
			if (doShow) {
				this.$nextTick().then(() => {
					const searchField = this.$refs.searchField;
					if (searchField == null || !(searchField instanceof HTMLInputElement)) return;

					searchField.focus();
					searchField.setSelectionRange(this.searchQuery.length * 2, this.searchQuery.length * 2);
				});
			} else {
				const searchField = this.$refs.searchField;
				if (!(searchField instanceof HTMLInputElement)) throw new Error('searchField is not an input element');
				searchField.blur();
			}
		}
	},

	mounted() {
		this.windowWidth = window.innerWidth;

		this.searchQuery = (document.querySelector("meta[name='search-query']") as HTMLMetaElement).content;

		document.addEventListener('turbo:visit', this.onVisit)
		window.addEventListener('resize', this.onResize);
		window.addEventListener('keydown', this.handleShortCut);
		window.addEventListener('click', this.onWindowClick);
	},

	beforeUnmount() {
		document.removeEventListener('turbo:visit', this.onVisit)
		window.removeEventListener('resize', this.onResize);
		window.removeEventListener('keydown', this.handleShortCut);
		window.removeEventListener('click', this.onWindowClick);
	},

	methods: {
		onFocusInSearchSuggestions(): void {
			this.searchPaneHasFocus = true;
		},

		onFocusOutSearchSuggestions(): void {
			this.searchPaneHasFocus = false;
			this.$nextTick(function () {
				if (document.activeElement !== this.$refs.searchField) this.currentSubPane = null;
			});
		},

		up(event: Event): void {
			if (!searchSuggestionsController.selectPrevious()) event.preventDefault()
		},

		down(): void {
			searchSuggestionsController.selectNext()
		},

		search(event: Event): void {
			if (this.searchQuery === '') return;

			event.preventDefault();

			const selectedSuggestion = searchSuggestionsController.selectedSuggestion();

			if (selectedSuggestion != null) {
				this.searchQuery = selectedSuggestion;
			}

			visit(searchPath(this.searchQuery));
		},

		onResize(): void {
			this.windowWidth = window.innerWidth
		},

		close(): void {
			this.currentSubPane = null;
		},

		toggleSearch(): void {
			if (this.showSearch) {
				this.currentSubPane = null
			} else {
				this.currentSubPane = 'search'
			}
		},

		toggleMenu(): void {
			if (this.horizontalMenu) return;

			if (this.showMenu) {
				this.currentSubPane = null
			} else {
				this.currentSubPane = 'menu'
			}
		},

		onVisit(): void {
			const params = new URLSearchParams(document.location.search.substring(1));
			const q = params.get("q");
			if (q != null && q != '') {
				this.searchQuery = q;
			} else {
				this.searchQuery = ''
			}

			this.close();
		},

		onSearchFieldFocus(): void {
			this.currentSubPane = 'search';
		},

		handleShortCut(event: KeyboardEvent): void {
			if (event.key === 's') {
				if (event.target !== document.body) return;
				this.toggleSearch();
				event.preventDefault();
				return
			}

			if (event.key === 'm') {
				if (event.target !== document.body) return;
				this.toggleMenu();
				event.preventDefault();
				return
			}

			if (event.keyCode === KeyCodes.ESCAPE) {
				this.close();
			}
		},

		onWindowClick(event: MouseEvent): void {
			const globalHeaderEl = document.querySelector(globalHeaderSelector);
			if (globalHeaderEl == null) return;
			if (globalHeaderEl.contains(event.target as HTMLElement)) return;
			this.close();
		}
	}
});
