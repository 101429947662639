import '@/br-jquery';
import mrujs from 'mrujs';
import { JsErb } from 'mrujs/plugins';
import { reloadOTBanner } from '@/one-trust';

import '@hotwired/turbo';
import '@/modernizr.custom';
import '@/toolbar';
import '@/global-header';
import '@/lot-list';
import '@/flash-messages';
import '@/lazy-loading-images';
import '@/bookmarks';
import '@/table-edge';
import '@/dropdown';
import '@/tooltips';
import '@/forms';
import '@/$relative-time';

import '@/currency-selector';
import '@/yass';
import '@/humanize-customer';
import '@/disclosure';
import '@/alignment';
import '@/tracking';
import '@/br-dom';
import '@/browser_check';
import '@/connection-status';
import '@/modal';
import '@/constrain-in-viewport';
import '@/upon-submit';
import '@/serviceworker-registration';
import '@/postal-addresses';

import '@/browser-fix';

import CurrencyAmount from '@/components/CurrencyAmount.ce.vue';
import CurrencyRange from '@/components/CurrencyRange.ce.vue';

import '@/sentry';

import { defineCustomElement } from 'vue';
import { MrujsPluginInterface } from '@/mrujs/dist/types';

customElements.define('currency-amount', defineCustomElement(CurrencyAmount));
customElements.define('currency-range', defineCustomElement(CurrencyRange));

mrujs.start({
	plugins: [JsErb() as unknown as MrujsPluginInterface],
});

document.addEventListener('turbo:render', () => {
	reloadOTBanner();
});
