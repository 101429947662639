import $ from '@/br-jquery';
import KeyCodes from '@/key-codes';

const close = function(this: HTMLElement) { return $(this).removeClass('open').removeClass('closing'); };

const closeFullScreenModal = function() {
	const $modal = $('.fullscreen-modal');
	if ($modal.is(':target')) {
		window.location.replace('#.#');
	} else {
		$('.fullscreen-modal').fadeOut('fast');
	}
	if ($modal.is('.ajax-link-target')) {
		$('.ajax-link-target').empty();
	}
};

$(document)
	.on('click', 'a[data-modal]', () => {
		$('.ajax-link-target').empty();
		$('.ajax-link-target').fadeIn(300);
	}).on('click touchstart', '.fullscreen-modal', function(e) {
		if (e.target !== this) { return; }

		closeFullScreenModal();
	}).on('click', '.close-fullscreen-modal', () => closeFullScreenModal()).keyup(function(e) {
		if ((e.keyCode === KeyCodes.ESCAPE) && $('.fullscreen-modal').is(':visible')) {
			closeFullScreenModal();
			e.preventDefault();
		}}).on('click', '.modal-close-button', function(e) {
		e.preventDefault();
		$(this).closest('.open').one('animationend', close).addClass('closing');
});
